import './App.css';
import {Routes,Route, Navigate} from 'react-router-dom'
import { AuthProvider } from './routes/auth';
import Home from './components/home';
import Projects from './components/projects';
import Vision from './components/vision';
import Team from './components/team';
import Blog from './components/blog-page/blog';
import NoMatch from './components/nomatch';
import Blogdetails from './components/blog-page/blogdetails';
import NavbarHome from './shared/navbarHome';
import About from './components/about';
import FooterView from './shared/foot';
import Causes from './components/causes';
import Contacts from './components/contacts';
import History from './components/history';
import ScrollToTop from './shared/scrollTo';
import MemberDetails from './components/memberdetails';
import CausesDetails from './components/causesDetails';
import MediaPage from './components/media/mediaPage';
function App() {


  return (
    <AuthProvider>
      <NavbarHome/>
      <>
        <ScrollToTop />
        <Routes> 
        <Route path="/" element={<Navigate to="/home" />} />
          <Route path='/home' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/about/members/:id' element={<MemberDetails/>}/>
          <Route path='/about/history' element={<History/>}/>
          <Route path='/projects' element={<Projects/>}/>
          <Route path='/works' element={<Causes/>}/>
          <Route path='/works/:id' element={<CausesDetails/>}/>
          <Route path='/contacts' element={<Contacts/>}/>
          <Route path='/media' element={<MediaPage/>}/>
          <Route path='/vision' element={<Vision/>}/>
          <Route path='/team' element={<Team/>}/>
          <Route path='/releases' element={<Blog/>}/>
          <Route path="/releases/:id" element={<Blogdetails/>} />
          <Route path='*' element={<NoMatch/>}></Route> 
      </Routes>
      </>
      <FooterView/>
    </AuthProvider>
  );
}
export default App;
