import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import NoMatch from './nomatch';

const CausesDetails = () => {

  const location = useLocation();
  const causeStory = location.state?.data;
  if (!causeStory) return <><NoMatch/> </>;
  return (
    <>
         <div className="container-fluid causes-header mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text">
          <Link className='btn btn-rounded btn-sm rounded text-white' to={'/works'}>  <i className="fa fa-arrow-left me-2"></i> To Our Causes </Link>
          <h1 className="display-4 fw-normal text-white animated  slideInDown mb-4">
             {causeStory.title}

          </h1>
             <p><small className='text-white fw-lighter'> {causeStory.date}</small></p>
        </div>
      </div>
       <div className="container">
        <div className="row ">
          <div className="col-12 mx-0">
          <img src={causeStory.imageUrl} className='w- rounded rounded-4                                                                                                                                                                                                                                                       '  alt="Shots"  height="320px"  
           style={{marginTop:'-8em',position:'relative'}}  />
          </div>
          <div className="col-11 col-lg-8 shadow  mb-5 pb-5 mx-auto">
            
             {
             causeStory.content.map((res,index) =>(
              <p className='mt-4' key={[index]} style={{'textAlign':'justify'}}>  
                  {res}   
              </p>
             
             ))}
           
          </div>
          <div className="col-12 mb-5">
            <h6 className="fw-light"> Other photos:</h6>
            <div className="row gx-3">
              <div className="col-2"> <img className="img-fluid rounded rounded-4 "  src="/assets/img/carousel/press.jpeg" alt=""/>  </div>
              <div className="col-2"> <img className="img-fluid rounded rounded-4 "  src="/assets/img/carousel/press.jpeg" alt=""/>  </div>
              <div className="col-2"> <img className="img-fluid rounded rounded-4 "  src="/assets/img/carousel/press.jpeg" alt=""/>  </div>
              <div className="col-2"> <img className="img-fluid rounded rounded-4 "  src="/assets/img/carousel/press.jpeg" alt=""/>  </div>
              <div className="col-2"> <img className="img-fluid rounded rounded-4 "  src="/assets/img/carousel/press.jpeg" alt=""/>  </div>
              <div className="col-2"> <img className="img-fluid rounded rounded-4 "  src="/assets/img/carousel/press.jpeg" alt=""/>  </div>
              
            </div>
          </div>
        </div>

        <div className="row match-height g-2">
            <h4 className='fw-bolder'> Other Developing Stories</h4>
          <div className="col-11 mb-4">
            <div className="row">
            <div className="col-12 col-md-6 col-lg-4 wow fadeInUp mt-4 "  style={{cursor:'pointer'}} data-wow-delay="0.1s">
              <Link to={'/works/'.concat('1')}>
                <div className="causes-item d-flex flex-column  bg-light border-top border-dark rounded-top overflow-hidden h-100">
                    <div className="text-center pt-0">
                        <div className="d-inline-block bg-danger text-white rounded-bottom fs-6 pb-1 px-3 mb-4">
                            <small>Elections </small>
                        </div>
                        {/* <h6 className="mb-3">FDAG in Madina Constituency</h6> */}
                        <p> <small className='fs-6 mb-2'> 
                        FDAG takes it's Community engagements to Madina Constituency
                          </small>
                          </p>
                    </div>
                    <div className="position-relative mt-auto">
                        <img className="w-100"  style={{maxHeight:'16.5em'}} src="/assets/img/carousel/press.jpeg" alt=""/>
                        <div className="causes-overlay">
                            <b className="btn-sm btn-white text-white border font-small rounded rounded-2 border-white py-2 px-3" to={'/works/'.concat('1')}>
                                Read More
                               
                            </b>
                        </div>
                    </div>
                </div>
                </Link>
            </div>
            </div>
          </div>
        </div>
       </div>

    </>
  )
}

export default CausesDetails
