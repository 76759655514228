import React from 'react'
import { Link } from 'react-router-dom'
const FooterView = () => {
  return (
    <div>
       <div className="container-fluid bg-dark text-white-50 footer  pt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-3">
            <div className="row g-5">
                <div className="col-lg-3 col-md-6">
                    <h1 className="fw-bold text-primary mb-4">FD<span className="text-white">AG</span></h1>
                    <p>Non political that aims to hold government accountable to the good people of Ghana</p>
                    <div className="d-flex pt-2">
                        <Link className="btn btn-sm btn-square me-1" target='_blank' to="/tiwter"> <i className="fab fa-twitter"></i></Link>
                        <Link className="btn btn-sm btn-square me-1"  target='_blank' to="https://www.facebook.com/profile.php?id=100077951392002"><i className="fab fa-facebook-f"></i></Link>
                        <Link className="btn btn-sm btn-square me-1"  target='_blank' to="/youtube"><i className="fab fa-youtube"></i></Link>
                        <Link className="btn btn-sm btn-square me-0"  target='_blank' to="/linked"><i className="fab fa-linkedin-in"></i></Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 h6">
                    <h5 className="text-light mb-4"> Postal Address</h5>
                    <p className='d-flex align-items-center' ><i className="fa fa-map-marker-alt me-3"></i>  P.O. Box AT, 1858, Achimota, Accra</p>
                    <p className='d-flex align-items-center '><i className="fa fa-phone-alt me-3"></i>(+233 )20 938-9765, <br/>  +1 (732) 496‑5113</p>
                    <p><i className="fa fa-envelope me-3"></i>info@fdag.com</p>
                </div>
                <div className="col-lg-2 col-md-6">
                    <h5 className="text-light mb-4">Quick Links</h5>
                    <Link className="btn btn-link" to="/about">About Us</Link>
                    <Link className="btn btn-link" to="/contacts">Contact Us</Link>
                    <Link className="btn btn-link" to="/works">Our Causes</Link>
                    <Link className="btn btn-link" to="/reach">Support</Link>
                </div>
               <div className="col-lg-3 col-md-6 ">
                    <h5 className="text-light mb-4">Get Involved</h5>
                    <p style={{textAlign:'justify'}}>Here at FDAG, we believe in the power of community and participation,Support our cause through donations</p>                
                    <p></p>
                    <button className='btn btn-outline-light px-4 py-2'> Donate </button>
                    
                </div>
            </div>
        </div>
        <div className="container-fluid copyright">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <b>FDAG</b> 2024, All Right Reserved.
                    </div>
                    <div className="col-md-6 text-center font-small-1 text-md-end">
                       <small className='font-small-1 text-muted' >  Designed By <a href="https://htmlcodex.com">D.A</a> </small> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default FooterView
