import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import NoMatch from '../nomatch';

const Blogdetails = () => {
  //  const {id} =   useParams();
   const location = useLocation();
   const blogDetails = location.state?.data;

   if (!blogDetails) return <><NoMatch/> </>;
  return (
    <>

      <div className="blogger-header mb-5 wow fadeIn" data-wow-delay="0.1s">
      <div className="container text-center">
            <h1 className="display-4 text-white fw-bold animated slideInDown mb-4"> {blogDetails.blogTitle} </h1>
             <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item"><Link className="text-white" to="/home">Home</Link></li>
                    <li className="breadcrumb-item " aria-current="page"><Link className="text-white" to={'/releases'} > Press Releases</Link>  </li>
                    <li className="breadcrumb-item text-primary active" aria-current="page"> Details </li>
                </ol>
            </nav> 
        </div>
      </div>
       <div className='container mt-5'>
        <div className="row  mt-5 ">
            <div className="col sm-11 col-md-7 col-lg-8 mx-auto">
            <h3 className='mb-3'>  {blogDetails.blogTitle} </h3>    
            {
              blogDetails.sections.map(((section,index) =>(
                <p className="text-primary" key={section[index]} style={{textAlign:'justify'}}>
                  {section}
                </p>
              )))
              
            }
            <p>Signed By :</p>
            {blogDetails.signees.map(sign=>(
              <>
              <b> {sign.signedBy} <br /></b>
              <p> {sign.signeeId} </p></>
            ))}
               
            </div>

        </div>
    </div>
    </>
  )
}

export default Blogdetails
