import React from 'react'
import { Link } from 'react-router-dom';

const History = () => {
  return (
    <>
      <div
        className="container-fluid history-header mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            About Us (FDAG)
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/home">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item text-primary active">
              <Link className="text-white" to="/about">
              About Us
              </Link> 
                </li>
              <li className="breadcrumb-item text-primary active">
                History
                </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto wow fadeInUp histText" data-wow-delay="0.5s">
              <div className="h-100">
                
                <h1 className="display-6 mb-5">
                  Forum for Development and Accountable Governance (FDAG)
                </h1>

      
                <p className="mb-2 fw-light text-justify">

                  We are a dedicated organization committed to advancing the principles of fairness,
                  justice, equity, and good governance, with an unwavering focus on fostering a corruption-free society that promotes development. 
                  Through active advocacy and engagement, we aim to promote democratic accountability, social justice, and uphold human rights for all citizens.
                </p>

              <h3 className='my-4'>Our Mission Statement</h3>
                <p className="mb-2 fw-light text-justify">
                     Our mission is to uphold and promote fairness, justice, equity, and equality.
                     We strive to expose corruption and ensure government and institutions meet the highest standards of transparency, integrity, and adherence to the rule of law. 
                     At FDAG, we are committed to championing policies and actions that protect the common good and safeguard the rights and welfare of all citizens.
                  </p>
   
              <h3 className='my-4'>Our Vision Statement</h3>
                <p className="mb-2 fw-light text-justify">
                  Our vision is to create a society where accountability and integrity are the foundational building blocks, 
                  and where corruption has no place. We aspire to build a just and equitable society with robust protection for human rights and a government held accountable to the people it serves.
                  </p>
                
              <h3 className='my-4'>Objectives</h3>
                <p className="mb-2 fw-light text-justify">
                  <p>Promote development, fairness, justice, and equity.</p>
                  <p>Foster a democratic society through advocacy and awareness.</p>
                  <p>Advocate for national interest policies that protect the common good.</p>
                  <p>Expose corrupt practices in all forms and promote a corruption-free society.</p>
                  <p>Ensure transparency, good governance, and accountability within governmental institutions.</p>
                  <p>Protect human rights and social justice for all citizens.</p>

                    </p>
                  
              <h3 className='my-4'>Our Core Values</h3>

                
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-grow text-primary" role="status"></div>
    </div> */}
      {/* <div className="conatainer fluid projectSection  mb-5">
        <div className='d-flex   '>
                <h4 className="text-center text-white d-flex bg-info"> About Us</h4>
        </div>
    </div> */}
      {/* <div className='container mt-5'>
        <div className="row mt-5">
            <div className="col-sm-12 col-md-9 col-lg-7 mx-auto fs-6 fw-lighter mt-5">
                <h2 className='fs-2 text-center'>About Us</h2>
                <p className='fs-6'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt molli.
                </p>
                <p className='fs-6'>
                 Praesent sit amet volutpat nulla. Duis aculis nisl non nulla rhoncus, eget accumsan velit euismod. Nunc turpis enim, porta vel ligula vel, scelerisque scelerisque augue. Ut tincidunt eleifend dui eget sodales. Fusce aliquet metus quis congue egestas. Morbi in gravida magna. Morbi pulvinar fringilla sapien, vel ullamcorper tortor iaculis vel. Donec
                et scelerisque nunc
                </p>
                <p className='fs-6'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra eleifend turpis.
                    Donec lacinia quam nisi, ut varius tellus rhoncus sed. Maecenas sed maximus ligula, ut malesuada eros. Aliquam elementum at erat nec mattis. Nulla mollis augue id ligula lobortis, in iaculis felis commodo. Sed suscipit sodales nisi, id maximus lacus lobortis eget. Nunc porttitor purus non condimentum viverra. Suspendisse nec finibus lacus, a mattis sem. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                    Morbi congue eu urna id venenatis. Morbi interdum porta ex sagittis consequat.
                </p>
                <p className='fs-6'>
                Pellentesque eget velit viverra, vestibulum purus in, luctus urna. Proin tincidunt, neque at fermentum sollicitudin, sem nisl pulvinar diam, sit amet elementum lorem nulla a leo. Curabitur id finibus arcu. Proin ornare quam lacus, et blandit felis pellentesque vitae. In sodales, felis a posuere accumsan, ipsum sem rutrum tortor, a aliquet massa
                ex sed risus. Nunc id magna sit amet sapien finibus hendrerit a nec tortor.
                Pellentesque ut nunc sed sapien lacinia varius. Fusce tincidunt diam id porttitor efficitur. Cras maximus justo ipsum, sit amet sagittis justo lobortis in.
                </p>
              </div>
        </div>
    </div> */}
    </>
  );
}

export default History
