import React from 'react'
// import './pubassets/css/media.css'
import "../media/media.css"

const MediaPage = () => {
  return (
    <>
         <div className="container-fluid press-header mb-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container text-center">
            <h1 className="display-4 text-white animated  fw-bolder slideInDown mb-4">
                Media
            </h1>
        </div>
      </div>
        <section className="bg-light py-5 py-xl-6">
        <div className="container mb-5 mb-md-6">
            <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 text-center">
                <h2 className="mb-4 display-5">Press Media</h2>
                 <p className="text-secondary mb-4 mb-md-5">View below a few photos from some of our outreach programmes. This shows the impact of our work in style.</p>
                <hr className="w-50 mx-auto mb-0 text-secondary"/>
            </div>
            </div>
        </div>


        <div className="container overflow-hidden">
            <div className="row gy-2 g-md-3 hcf-isotope-grid">
            <div className="col-12 col-md-5 col-lg-3 hcf-isotope-item">
                <div className="row gy-1">
                    <div className="col-12">
                    <a className="hcf-masonry-card rounded rounded-4" href="#!">
                        <img className="card-img img-fluid" loading="lazy" src="./assets/img/media/press1.jpeg" alt=""/>
                        <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                            <h3 className="card-title text-dark text-center mb-1">Charming Concept</h3>
                            <div className="card-category text-white text-center">Photography</div>
                        </div>
                        </a>
                    </div>
                    <div className="col-12">
                    <a className="hcf-masonry-card rounded rounded-4" href="#!">
                        <img className="card-img img-fluid" loading="lazy" src="./assets/img/media/press1.jpeg" alt=""/>
                        <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                            <h3 className="card-title text-dark text-center mb-1">Charming Concept</h3>
                            <div className="card-category text-white text-center">Photography</div>
                        </div>
                        </a>
                    </div>
                </div>
            </div>
            {/* BIG MEDIA */}
            <div className="col-12 col-md-6 col-lg-6 hcf-isotope-item">
                <a className="hcf-masonry-card rounded rounded-4" href="#!">
                <img className="card-img img-fluid" loading="lazy"  src="./assets/img/media/press1.jpeg" alt=""/>
                <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                    <h3 className="card-title text-dark text-center mb-1">Linear Architecture</h3>
                    <div className="card-category text-white text-center">Inspiration</div>
                </div>
                </a>
            </div>
            {/* ENED OF BIG MEDIA */}
            <div className="col-12 col-md-6 col-lg-3 hcf-isotope-item">
                <a className="hcf-masonry-card rounded rounded-4" href="#!">
                <img className="card-img img-fluid" loading="lazy" src="./assets/img/media/press1.jpeg" alt=""/>
                <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                    <h3 className="card-title text-dark text-center mb-1">Endless Looks</h3>
                    <div className="card-category text-white text-center">Nature</div>
                </div>
                </a>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-4">
                <div className=" card p-0 rounded rounded-4 mt-2 ">
                <iframe className="embed-responsive-item  rounded rounded-4" width={'100%'} height={'100%'}
                src=""  
                title='intro' allowFullScreen></iframe>
                </div>
            </div>
           
            <div className="col-12 col-md-4 col-lg-4 hcf-isotope-item">
                <a className="hcf-masonry-card rounded rounded-4" href="#!">
                <img className="card-img img-fluid" loading="lazy"  src="./assets/img/media/press1.jpeg" alt=""/>
                <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                    <h3 className="card-title text-dark text-center mb-1">Sleek Typography</h3>
                    <div className="card-category text-white text-center">Design</div>
                </div>
                </a>
            </div>
            <div className="col-12 col-md-4 col-lg-5 hcf-isotope-item">
                <a className="hcf-masonry-card rounded rounded-4" href="#!">
                <img className="card-img img-fluid" loading="lazy"  src="./assets/img/media/press1.jpeg" alt=""/>
                <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                    <h3 className="card-title text-dark text-center mb-1">Ebony Vintage</h3>
                    <div className="card-category text-white text-center">Fashion</div>
                </div>
                </a>
            </div>
            <div className="col-12 col-md-4 col-lg-3 hcf-isotope-item">
                <a className="hcf-masonry-card rounded rounded-4" href="#!">
                <img className="card-img img-fluid" loading="lazy"  src="./assets/img/media/press1.jpeg" alt=""/>
                <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                    <h3 className="card-title text-dark text-center mb-1">Orange Shine</h3>
                    <div className="card-category text-white text-center">Food</div>
                </div>
                </a>
            </div>
            <div className="col-12 col-md-4 col-lg-4 hcf-isotope-item">
                <a className="hcf-masonry-card rounded rounded-4" href="#!">
                <img className="card-img img-fluid" loading="lazy"  src="./assets/img/media/press1.jpeg" alt=""/>
                <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                    <h3 className="card-title text-dark text-center mb-1">Boat Adventure</h3>
                    <div className="card-category text-white text-center">Health</div>
                </div>
                </a>
            </div>
            </div>
        </div>



        
        <div className="container mt-5 overflow-hidden">
            <div className="row gy-3 g-md-3 hcf-isotope-grid">
            <div className="col-12 col-md-6 col-lg-3 hcf-isotope-item">
                <a className="hcf-masonry-card rounded rounded-4" href="#!">
                <img className="card-img img-fluid" loading="lazy"  src="./assets/img/media/press1.jpeg" alt=""/>
                <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                    <h3 className="card-title text-dark text-center mb-1">Offroad Moves</h3>
                    <div className="card-category text-white text-center">Nature</div>
                </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-lg-3 hcf-isotope-item">
                <a className="hcf-masonry-card rounded rounded-4" href="#!">
                <img className="card-img img-fluid" loading="lazy" src="./assets/img/media/press1.jpeg" alt=""/>
                <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                    <h3 className="card-title text-dark text-center mb-1">Charming Concept</h3>
                    <div className="card-category text-white text-center">Photography</div>
                </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-lg-3 hcf-isotope-item">
                <a className="hcf-masonry-card rounded rounded-4" href="#!">
                <img className="card-img img-fluid" loading="lazy" src="./assets/img/media/press1.jpeg" alt=""/>
                <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                    <h3 className="card-title text-dark text-center mb-1">Endless Looks</h3>
                    <div className="card-category text-white text-center">Nature</div>
                </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-lg-3 hcf-isotope-item">
                <a className="hcf-masonry-card rounded rounded-4" href="#!">
                <img className="card-img img-fluid" loading="lazy"  src="./assets/img/media/press1.jpeg" alt=""/>
                <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                    <h3 className="card-title text-dark text-center mb-1">Sleek Typography</h3>
                    <div className="card-category text-white text-center">Design</div>
                </div>
                </a>
            </div> 
            <div className="col-12 col-md-6 col-lg-3 hcf-isotope-item">
                <a className="hcf-masonry-card rounded rounded-4" href="#!">
                <img className="card-img img-fluid" loading="lazy"  src="./assets/img/media/press1.jpeg" alt=""/>
                <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                    <h3 className="card-title text-dark text-center mb-1">Ebony Vintage</h3>
                    <div className="card-category text-white text-center">Fashion</div>
                </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-lg-6 hcf-isotope-item">
                <a className="hcf-masonry-card rounded rounded-4" href="#!">
                <iframe className="embed-responsive-item" width={'100%'} height="311"
                     src=""  
                    title='intro' allowFullScreen></iframe>
                <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                    <h3 className="card-title text-dark text-center mb-1">Linear Architecture</h3>
                    <div className="card-category text-white text-center">Inspiration</div>
                </div>
                </a>
            </div>
           
            <div className="col-12 col-md-6 col-lg-3 hcf-isotope-item">
                <a className="hcf-masonry-card rounded rounded-4" href="#!">
                <img className="card-img img-fluid" loading="lazy"  src="./assets/img/media/press1.jpeg" alt=""/>
                <div className="card-overlay d-flex flex-column justify-content-center bg- p-4" style={{"opacity": .5}}>
                    <h3 className="card-title text-dark text-center mb-1">Boat Adventure</h3>
                    <div className="card-category text-white text-center">Health</div>
                </div>
                </a>
            </div>
            </div>
        </div>
        </section>
    </>
  )
}

export default MediaPage
