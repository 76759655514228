import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';


const About = () => {

  const [team, setTeam] = useState([]);
  const [spinner,setSpinner]= useState(true);
  useEffect(() => { 
      fetch('/assets/json/team.json')
          .then(response => response.json())
          .then(data => {
            setTeam(data);
              setSpinner(false);})
          .catch(error => console.error("Error loading data:", error));
  }, []);

  return (
    <>
      <div
        className="container-fluid about-header mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center">
          <h1 className="display-4 fw-bold text-white animated slideInDown mb-4">
            About Us
          </h1>
         
        </div>
      </div>
      <div className="container py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="position-relative overflow-hidden h-50 h-sm-25"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100  h-100 pt-5 pe-5"
                  src="assets/img/logo.jpeg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="d-inline-block rounded-pill bg-second text-primary fs-6 py-1 px-3 mb-3">
                  {" "}
                  <small> About Us</small>{" "}
                </div>
                <h1 className="display-6 mb-4" style={{ fontWeight: 700 }}>
                  Welcome to the Forum for Development and Accountable Governance (FDAG).
                </h1>
                <div className="bg-light border-bottom border-4 border-dark rounded p-3  mb-4">
                  <p className="text-dark  mb-2">
                    We are a dedicated organization committed to advancing the principles of fairness, 
                    justice, equity, and good governance, with an unwavering focus on fostering a corruption-free society that promotes development
                    Through active advocacy and engagement, we aim to promote democratic accountability, social justice, and uphold 
                   human rights for all citizens.
                  </p>
                  <span className="text-primary">FDAG</span>
                </div>
                
                <Link className="btn btn-dark btn-sm py-2 px-3 me-3" to="/about/history">
                    Read More <i className="fa fa-arrow-right ms-2"></i>
                </Link>
                <Link className="btn btn-outline-dark btn-sm py-2 px-3 me-3" to="/contacts">
                    Contact Us  <i className="fa fa-arrow-right ms-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="conatainer-fluid   mb-5"></div>

      <div className="container">
        <div className="row  bg-light-info ">
          <div className="col-sm-12 col-md-6 p-5 text-primary">
            <h3 className="mt-2 text-start"> Our Mission </h3>
            <p className=" fs-6 mt-4 mb-3 fw-lighter" style={{textAlign:'justify'}}>
            Our mission is to uphold and promote fairness, justice,
             equity, and equality. We strive to expose corruption 
             and ensure government and institutions meet the highest
              standards of transparency, integrity, and adherence to
               the rule of law. At FDAG, we are committed to 
               championing policies and actions that protect the
                common good and safeguard the rights and welfare of
                 all citizens.
            </p>

          </div>
          <div className="col-sm-12 col-md-6 p-5">
            <img
              src="/assets/img/about/mission.jpeg"
              className="img-fluid"
              alt=""
              height="550"
            />
          </div>
          <div className="col-sm-12 col-md-6 p-5">
            <img
              src="/assets/img/about/vision.jpeg"
              className="img-fluid"
              alt=""
              width="480"
              height="300"
            />
          </div>
          <div className="col-sm-12 col-md-6 p-5  bg-light text-primary">
            <h3 className="mt-2">Our Vision</h3>
            <p className="fw-lighter mt-4 mb-3" style={{textAlign:'justify'}}>
              <small>
              Our vision is to create a society where accountability
               and integrity are the foundational building blocks, 
               and where corruption has no place. We aspire to build
                a just and equitable society with robust protection 
                for human rights and a government held accountable 
                to the people it serves.
              </small>
            </p>
          </div>
        </div>
      </div>
      {/* OUR TEAM SECTIONS */}

      <div className="container mt-5">
        <div className="row  mx-auto mt-5">
          <div className="col-sm-12  col-md-12 col-lg-12 mx-auto fs-6 fw-lighter mt-5">
            <h2 className="fs-2 text-center">Our Team</h2>
            <div className="container">
              <div
                className="text-center mx-auto mb-5 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ maxWidth: "500px" }}
              >
                <h1 className="display-6 fw-bold mb-5">
                  Let's Meet With The Team 
                </h1>
              </div>
              <div className="row g-3">
                {team.map((member, index) => (
                  <div
                    className="col-lg-4 col-md-6 wow px-4 fadeInUp"
                    data-wow-delay={member.delay}
                    key={index} >
                    <div className="team-item position-relative  rounded overflow-hidden">
                      <div className="overflow-hidden mx-auto">
                        <img className=" w-100 "  src={member.imageUrl} alt="" height={"370px"} />
                      </div>
                      <div className="team-text bg-light text-center p-4">
                        <h6 className="fw-bold">{member.name}</h6>
                        <p className="text-primary">{member.position}</p>

                        <div className="team text-center">
                          <Link className="btn btn-sm btn-link " style={{textDecoration:'none',color:'black'}} to={'/about/members/'+ member.id}  state={{member}}>
                            Read More
                          </Link>
                          {/* <Link className="btn btn-square" to="/">
                            <i className="fab fa-twitter"></i>
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About
