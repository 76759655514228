import React from 'react'
import Form from './form'
import { Link } from 'react-router-dom'

const Contacts = () => {
  return (
    <div>
      
      <div className="container-fluid contact-header mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
            <h1 className="display-4 text-white fw-bold animated slideInDown mb-4">Contact </h1>
             <nav aria-label="breadcrumb animated slideInDown">
                {/* <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item"><Link className="text-white" to="/">Home</Link></li>
                    <li className="breadcrumb-item text-primary active" aria-current="page">Contact Us</li>
                </ol> */}
            </nav> 
        </div>
    </div>

    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div className="d-inline-block rounded-pill bg-light text-primary py-1 px-3 mb-3">Contact Us</div>
                      <h4 className="display-6 mb-8">For inquiries or to learn more about our work, please reach out using the contact information below.Us</h4>
                    <div className="row mb-3">
                        <div className="col-6">
                        <h6 className='fw-bold mb-0 mt-2'>Email</h6>
                        <p className='fw-light'>  <small>  info@fdag.com </small> </p>
                       
                        </div>
                        <div className="col-6">
                        <h6 className='fw-bold mb-0 mt-2'>  Address</h6>
                        <p className='fw-light'> <small>  Building No. 20, Onyinase Lane 14, Awoshie, Accra, GH </small></p>
                        <p className='fw-light'>  <small> Near Ordorgonno SHS, Awoshie District, Weija Municipal </small> </p>
                        <p className='fw-light'>  <small> GPS Address: GS-0277-0790 </small> </p>
                       
                        </div>
                        <div className="col-6">
                        <h6 className='fw-bold mb-0 mt-2'>Phone</h6>
                        <p className='fw-light fs-6'> <small> (+233 )20 938-9765 <br />  +1 (732) 496‑5113 <br/>(+233) 24  955-2709 </small></p>
                        

                        </div>
                    </div>
                    <Form/>
                
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{minHeight: "450px"}}>
                    <div className="position-relative rounded overflow-hidden h-100">
                    <iframe title='headOfficeframe' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d32698.857106092146!2d-0.21345240922595887!3d5.567316380565741!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9084b2b7a773%3A0xbed14ed8650e2dd3!2sAccra!5e0!3m2!1sen!2sgh!4v1731151432975!5m2!1sen!2sgh" 
                    style={{minHeight: "450px", border:0}}  width="600" height="450"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        {/* <iframe  className="position-relative w-100 h-100"
                        src="https://www.google.com/maps/place/Accra/@5.5653252,-0.1969814,14z/data=!4m6!3m5!1s0xfdf9084b2b7a773:0xbed14ed8650e2dd3!8m2!3d5.5592846!4d-0.1974306!16zL20vMGZueWM?hl=en&entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D"
                        frameborder="0" style={{minHeight: "450px", border:0}} allowfullscreen="" aria-hidden="false"
                        tabindex="0"></iframe> */}

                        {/* 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                        
                        */}
                       <div className="col-sm-12 col-md-12 p-5 mt-4  bg-light text-primary">
                       <h3 className="mt-2">GET INVOLVED</h3>
                       <p className="fw-dark mt-4 mb-3" style={{textAlign:'justify'}}>
                       <small>
                        At FDAG, we believe in the power of community and participation. There are many ways you can get involved:
                         <p> Join as a member or volunteer </p>
                         <p> Participate in our events and initiatives </p>
                         <p> Support our cause through donations </p>

              </small>
            </p>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Contacts
