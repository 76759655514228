import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const MemberDetails = () => {
  const location = useLocation();
  const memberDetail = location.state?.member;

  console.log(memberDetail);
  
  return (
    <>
     <div className="details-header mb-5 wow fadeIn" data-wow-delay="0.1s">
      <div className="container text-center">
            <h1 className="display-4 text-white fw-bold animated slideInDown mb-4"> {memberDetail.name} </h1>
             <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item"><Link className="text-white" to="/home">Home</Link></li>
                    <li className="breadcrumb-item " aria-current="page"><Link className="text-white" to={'/about'} > About Us </Link>  </li>
                    <li className="breadcrumb-item text-primary active" aria-current="page"> Profile </li>
                </ol>
            </nav> 
        </div>
      </div>
      <div className="container my-5 pb-5" >

          <div className="row align-items-top mx-1">
                <div className=" d-sm-none d-md-block col-md-5 col-lg-4 mb-3  mb-md-0">
                    <img
                        src={memberDetail.imageUrl}
                        alt="Profile"
                        className="img-fluid  shadow-sm"
                    />
                </div>
                <div className="col-md-7 ">
                    <h1 className="mb-3 mt-4">Profile</h1>
                    <p className='fw-light' style={{textAlign:'justify'}}>
                       {memberDetail.content?.bio || 'No Bio data'}
                      </p>
                </div>

            </div>
            <div className="row mx-3  mb-4">
              <div className="col-12 mb-4">

              <div className='my-5'>
                {/* <h1 className="mb-3"> </h1> */}
                
                <p lassName='fw-light' style={{textAlign:'justify'}}> 
                {memberDetail.content?.intro || 'No life experience story'}
                </p>

                </div>
                <h1 className="mt-4"> </h1>
                <p lassName='fw-light' style={{textAlign:'justify'}}> 
                {memberDetail.content?.current || 'No current affairs'}
                </p>
              </div>
            </div>
       </div>

    </>
  )
}

export default MemberDetails
