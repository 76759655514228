import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const Causes = () => {

  const [stories, setBlogs] = useState([]);
  const [spinner,setSpinner]= useState(true);

  useEffect(() => { 
      fetch('/assets/json/blog.json')
          .then(response => response.json())
          .then(data => {
              setBlogs(data);
              setSpinner(false);})
          .catch(error => console.error("Error loading data:", error));
  }, []);


  return (
    <>
      <div
        className="container-fluid causes-header mb-5 wow fadeIn"
        data-wow-delay="0.1s">
        <div className="container text-center">
          <h1 className="display-4 text-white animated fw-bolder  slideInDown mb-4">
            Our Causes
          </h1>
         
        </div>
      </div>
{/* 
 <div className="d-inline-block rounded-pill bg-light  text-primary py-1 px-3 mb-3">
                  Our Causes
                </div> */}

      <div className="container-xxl pt-5">
        <div className="container-fluid">
          <div className="row mx-4">
          {/* MAIN NEWS */}
            <div className="col-12 col-md-7 col-lg-7 wow fadeInUp" data-wow-delay="0.5s">
            <img className="rounded img-fluid rounded-3 mb-3"
                  src="assets/img/logoo.jpg"
                  alt=""/>
                <h6><small className='text-danger   fw-light'> FDAG </small> </h6>
                <h5 className=" fw-bold mb-2 ">   Forum for Development and Accountable Governance </h5>
                  <p className=" font-small-1" style={{textAlign:'justify'}}>
                    Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit.
                    Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit,
                    sed stet lorem sit clita duo justo magna dolore erat amet
                  </p>
              
              <p className=' font-small-2'> <small>   October 28,2024 </small></p>
            </div>

            {/* SUB NEWS */}
            <div className="col-12 col-md-5 col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              <div className="row">
                <div className="col-12 mb-5">
                  <div className=" d-flex align-items-center justify-content-center  ">
                    <div className="col-5">
                        <img className="rounded img-fluid rounded-3 mb-3"
                      src="assets/img/logoo.jpg"
                      alt="" />

                    </div>
                    <div className="ms-3">
                        <p style={{'fontSize':'0.8em'}}><small className='text-danger   fw-light'> FDAG </small> </p>
                        <small className=" fw-bolder">
                         Aliqu . Clita erat ipsum et lorem et sit, ipsum et lorem et sit,
                       
                        </small>
                        <p style={{'fontSize':'0.75em'}} className='mt-3'>September 10, 2024 </p>
                    </div>
                  </div>
              
                </div>
                <div className="col-12 mb-5">
                  <div className=" d-flex align-items-center justify-content-center  ">
                    <div className="col-5">
                        <img className="rounded img-fluid rounded-3 mb-3"
                      src="assets/img/logoo.jpg"
                      alt="" />

                    </div>
                    <div className="ms-3">
                        <p style={{'fontSize':'0.8em'}}><small className='text-danger   fw-light'> FDAG </small> </p>
                        <small className=" fw-bolder">
                         Aliqu . Clita erat ipsum et lorem et sit, ipsum et lorem et sit,
                       
                        </small>
                        <p style={{'fontSize':'0.75em'}} className='mt-3'>September 10, 2024 </p>
                    </div>
                  </div>
              
                </div>
                <div className="col-12 mb-5">
                  <div className=" d-flex align-items-center justify-content-center  ">
                    <div className="col-5">
                        <img className="rounded img-fluid rounded-3 mb-3"
                      src="assets/img/causes.jpeg"
                      alt="" />

                    </div>
                    <div className="ms-3">
                        <p style={{'fontSize':'0.8em'}}><small className='text-danger   fw-light'> FDAG </small> </p>
                        <small className=" fw-bolder">
                         Aliqu . Clita erat ipsum et lorem et sit, ipsum et lorem et sit,
                       
                        </small>
                        <p style={{'fontSize':'0.75em'}} className='mt-3'>September 10, 2024 </p>
                    </div>
                  </div>
              
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    {/* OTHER STORIES SECTION */}
    <div className="container-fluid causesBg mt-5  mb-0">
        
      <div className="row g-4 pb-0   mt-5">
        <div className="col-10 mx-auto">

          <div className="row ">
            <h2 className='text-white fw-bolder mb-2'> OTHERS NEWS </h2>
            <div className="col-12 col-md-6 col-lg-4 wow fadeInUp mt-4 "  style={{cursor:'pointer'}} data-wow-delay="0.1s">
              <Link to={'/works/'.concat('1')}>
                <div className="causes-item d-flex flex-column  bg-light border-top border-dark rounded-top overflow-hidden h-100">
                    <div className="text-center pt-0">
                        <div className="d-inline-block bg-danger text-white rounded-bottom fs-6 pb-1 px-3 mb-4">
                            <small>FDAG</small>
                        </div>
                        {/* <h6 className="mb-3">FDAG in Madina Constituency</h6> */}
                        <p> <small className='fs-6 mb-2'> 
                        FDAG takes it's Community engagements to Madina Constituency
                          </small>
                          </p>
                    </div>
                    <div className="position-relative mt-auto">
                        <img className="w-100"  style={{maxHeight:'16.5em'}} src="assets/img/carousel/logo.jpeg" alt=""/>
                        <div className="causes-overlay">
                            <b className="btn-sm btn-white text-white border font-small rounded rounded-2 border-white py-2 px-3" to={'/works/'.concat('1')}>
                                Read More
                               
                            </b>
                        </div>
                    </div>
                </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-lg-4 wow fadeInUp mt-4 "  style={{cursor:'pointer'}} data-wow-delay="0.1s">
              <Link to={'/works/'.concat('1')}>
                <div className="causes-item d-flex flex-column  bg-light border-top border-dark rounded-top overflow-hidden h-100">
                    <div className="text-center pt-0">
                        <div className="d-inline-block bg-danger text-white rounded-bottom fs-6 pb-1 px-3 mb-4">
                            <small>FDAG </small>
                        </div>
                        {/* <h6 className="mb-3">FDAG in Madina Constituency</h6> */}
                        <p> <small className='fs-6 mb-2'> 
                        FDAG takes it's Community engagements to Madina Constituency
                          </small>
                          </p>
                    </div>
                    <div className="position-relative mt-auto">
                        <img className="w-100"  style={{maxHeight:'16.5em'}} src="assets/img/carousel/logo.jpeg" alt=""/>
                        <div className="causes-overlay">
                            <b className="btn-sm btn-white text-white border font-small rounded rounded-2 border-white py-2 px-3" to={'/works/'.concat('1')}>
                                Read More
                               
                            </b>
                        </div>
                    </div>
                </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-lg-4 wow fadeInUp mt-4 "  style={{cursor:'pointer'}} data-wow-delay="0.1s">
              <Link to={'/works/'.concat('1')}>
                <div className="causes-item d-flex flex-column  bg-light border-top border-dark rounded-top overflow-hidden h-100">
                    <div className="text-center pt-0">
                        <div className="d-inline-block bg-danger text-white rounded-bottom fs-6 pb-1 px-3 mb-4">
                            <small>FDAG</small>
                        </div>
                        {/* <h6 className="mb-3">FDAG in Madina Constituency</h6> */}
                        <p> <small className='fs-6 mb-2'> 
                        FDAG takes it's Community engagements to Madina Constituency
                          </small>
                          </p>
                    </div>
                    <div className="position-relative mt-auto">
                        <img className="w-100"  style={{maxHeight:'16.5em'}} src="assets/img/carousel/logo.jpeg" alt=""/>
                        <div className="causes-overlay">
                            <b className="btn-sm btn-white text-white border font-small rounded rounded-2 border-white py-2 px-3" to={'/works/'.concat('1')}>
                                Read More
                               
                            </b>
                        </div>
                    </div>
                </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-lg-4 wow fadeInUp mt-4 "  style={{cursor:'pointer'}} data-wow-delay="0.1s">
              <Link to={'/works/'.concat('1')}>
                <div className="causes-item d-flex flex-column  bg-light border-top border-dark rounded-top overflow-hidden h-100">
                    <div className="text-center pt-0">
                        <div className="d-inline-block bg-danger text-white rounded-bottom fs-6 pb-1 px-3 mb-4">
                            <small>FDAG</small>
                        </div>
                        {/* <h6 className="mb-3">FDAG in Madina Constituency</h6> */}
                        <p> <small className='fs-6 mb-2'> 
                        FDAG takes it's Community engagements to Madina Constituency
                          </small>
                          </p>
                    </div>
                    <div className="position-relative mt-auto">
                        <img className="w-100"  style={{maxHeight:'16.5em'}} src="assets/img/carousel/logo.jpeg" alt=""/>
                        <div className="causes-overlay">
                            <b className="btn-sm btn-white text-white border font-small rounded rounded-2 border-white py-2 px-3" to={'/works/'.concat('1')}>
                                Read More
                               
                            </b>
                        </div>
                    </div>
                </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-lg-4 wow fadeInUp mt-4 "  style={{cursor:'pointer'}} data-wow-delay="0.1s">
              <Link to={'/works/'.concat('1')}>
                <div className="causes-item d-flex flex-column  bg-light border-top border-dark rounded-top overflow-hidden h-100">
                    <div className="text-center pt-0">
                        <div className="d-inline-block bg-danger text-white rounded-bottom fs-6 pb-1 px-3 mb-4">
                            <small>FDAG </small>
                        </div>
                        {/* <h6 className="mb-3">FDAG in Madina Constituency</h6> */}
                        <p> <small className='fs-6 mb-2'> 
                        FDAG takes it's Community engagements to Madina Constituency
                          </small>
                          </p>
                    </div>
                    <div className="position-relative mt-auto">
                        <img className="w-100"  style={{maxHeight:'16.5em'}} src="assets/img/carousel/logo.jpeg" alt=""/>
                        <div className="causes-overlay">
                            <b className="btn-sm btn-white text-white border font-small rounded rounded-2 border-white py-2 px-3" to={'/works/'.concat('1')}>
                                Read More
                               
                            </b>
                        </div>
                    </div>
                </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-lg-4 wow fadeInUp mt-4 "  style={{cursor:'pointer'}} data-wow-delay="0.1s">
              <Link to={'/works/'.concat('1')}>
                <div className="causes-item d-flex flex-column  bg-light border-top border-dark rounded-top overflow-hidden h-100">
                    <div className="text-center pt-0">
                        <div className="d-inline-block bg-danger text-white rounded-bottom fs-6 pb-1 px-3 mb-4">
                            <small>FDAG</small>
                        </div>
                        {/* <h6 className="mb-3">FDAG in Madina Constituency</h6> */}
                        <p> <small className='fs-6 mb-2'> 
                        FDAG takes it's Community engagements to Madina Constituency
                          </small>
                          </p>
                    </div>
                    <div className="position-relative mt-auto">
                        <img className="w-100"  style={{maxHeight:'16.5em'}} src="assets/img/carousel/logo.jpeg" alt=""/>
                        <div className="causes-overlay">
                            <b className="btn-sm btn-white text-white border font-small rounded rounded-2 border-white py-2 px-3" to={'/works/'.concat('1')}>
                                Read More
                               
                            </b>
                        </div>
                    </div>
                </div>
                </Link>
            </div>
           
         </div>
       </div>
      </div>
      
    </div>
    
      {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-grow text-primary" role="status"></div>
    </div> */}
      {/* <div className="conatainer fluid projectSection  mb-5">
        <div className='d-flex   '>
                <h4 className="text-center text-white d-flex bg-info"> About Us</h4>
        </div>
    </div> */}
  </>
  );
}

export default Causes
