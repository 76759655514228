import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import Form from './form'

const Home = () => {

  const [news, setNews] = useState([]);
  const [spinner,setSpinner]= useState(true);
  useEffect(() => { 
      fetch('/assets/json/causes.json')
          .then(response => response.json())
          .then(data => {
            setNews(data);
              setSpinner(false);})
          .catch(error => console.error("Error loading data:", error));
  }, []);

  return (
    <>
    <div className="container-fluid p-0 mb-5">
        <div id="header-carousel" className="carousel slide" data-bs-interval="2000" data-bs-ride="carousel">
            <div className="carousel-inner ">
              {[
                { imgSrc: "/assets/img/carousel/slide4.jpeg", subtext: "A world of benfits",status:' carousel-item active' },
                { imgSrc: "/assets/img/carousel/slide5.jpeg", subtext: "A world of benfits", status:'carousel-item' },
                { imgSrc: "/assets/img/carousel/logo2.jpeg", subtext: "A presitogus",status:'carousel-item '  },
                { imgSrc: "/assets/img/carousel/slide6.jpeg", subtext: "A world of benfits",status:'carousel-item '  },
              ].map((slider,index) => (
                <div className={slider.status + ' text-center  '}  key={index}>
                    <img className="img-fluid d-flex align-items-center " src={slider.imgSrc} alt={slider.subtext}/>
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 ">
                                    <h1 className=" display-3  fw-bolder text-white mb-3 fw-bold animated slideInDown">                        
                                    FORUM FOR DEVELOPMENT AND ACCOUNTABLE GOVERNANCE
                                    </h1>                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              ))}
               
              {/* <div className="carousel-item active ">
                  <img class="w-100" src="/assets/img/school_nigeria.png" alt="slider1" />
                  <div class="carousel-caption">
                      <div class="container">
                          <div class="row justify-content-center">
                              <div class="col-lg-7 pt-5">
                                  <h1 class="display-4 text-white mb-3 animated slideInDown">Let's Save More Lifes With Our Helping Hand</h1>
                                  <p class="fs-5 text-white-50 mb-5 animated slideInDown">Aliqu diam amet diam et eos. Clita erat ipsum et lorem sed stet lorem sit clita duo justo erat amet</p>
                                  <a class="btn btn-primary py-2 px-3 animated slideInDown" href="/">
                                      Learn More
                                      <div class="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                          <i class="fa fa-arrow-right"></i>
                                      </div>
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> */}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>


    <div className='container-fluid mt-0 '>
    <div className='row heroSection d-none mb-5'>
      <div className='col-sm-12 col-md-12  d-flex align-items-end pb-3' >
       <h1 className='text-white fs-2  fw-bold text-center mx-3 mb-0 display-3'>
         FORUM FOR DEVELOPMENT AND ACCOUNTABLE GOVERNANCE
        </h1>
      </div>
    </div>
    <div className='row mx-5 my-4 py-5  g-4'>

       <div className="col-sm-12 col-md-6 fw-light">
        <p className='fs-2 fw-bolder text-primary'> 
        Welcome to the Forum for Development and Accountable Governance (FDAG).
        
          </p> 
        <p style={{textAlign:'justify'}}>  
        We are a dedicated organization committed to advancing the principles of fairness, 
        justice, equity, and good governance, with an unwavering focus on fostering a corruption-free society that promotes development
        Through active advocacy and engagement, we aim to promote democratic accountability, social justice, and uphold human rights for all citizens.
        </p>
        </div>
        <div className="col-sm-12 col-md-6 text-end">
        <h3 className='fs-2 mb-3' > Our Mission and Our Vision </h3>
      <Link  className="btn btn-danger text-center ms-auto rounded-0 btn-sm px-4 mt-3 py-2" to="/about"> Read More </Link>
        </div>
      
       
     </div>

     
    <div className="row mt-5 bg-light">
     
        <h3 className='fs-2 mb-5 mt-5 text-center  text-primary' > Latest News  and  Events </h3>
        <div className="col-12 mt-3 mb-4">
            <div className="row mx-4 my-2 gx-4 card-group match-height">
              {news.slice(0,6).map((data)=>(
              <div key={data.id} className="col-sm-12 col-md-6 col-lg-4 p-3  match-height">
                <div className="card border-0 shadow ">
                <Link style={{cursor:'pointer',textDecoration:'none'}}  to={'/works/'.concat(data.id)} state={{ data }} >
                    <img src={data.imageUrl}  style={{maxHeight:'18.5em'}} className="card-img-top" alt="..Alternative image."/>
                    <div className="card-body">
                      <h6 className="card-title"> <b className='text-primary'>{data.title} </b>
                       <p><small className='text-danger'> {data.date} </small></p>
                      </h6>
                      <p className="card-text fs-6" style={{textAlign:'justify'}}> <small>  {data.description}   </small> </p>
                    </div>
                  </Link>
                  </div>  
                </div>

               ))}
            </div>
    
        </div>
      </div>
    
      <div className="row bg-light ">
        <h3 className='fs-2 my-5 text-center text-primary ' > Our Current  Initiatives </h3>
        <div className="col-12  mt-3 mb-5">
        <div className="row g-4 mb-4  justify-content-center">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                        <i className="fa fa-bullhorn fa-4x mb-3 text-primary"/>
                        <h4 className="mb-3 text-primary">Advocacy and Awareness</h4>
                        <p className="mb-4">Engaging communities and stakeholders to raise awareness on issues related to social justice, accountability, and governance.</p>
                        <Link className="btn btn-outline-primary  btn-sm " to="/works">
                            Read More
                            <div className="d-inline-flex btn-sm-square bg-outline-primary text-white rounded-circle ms-2">
                                <i className="fa fa-arrow-right"></i>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                        <i className="fa fa-book mb-3 fa-4x text-primary "/>
                        <h4 className="mb-3 text-primary">Anti-Corruption Initiatives</h4>
                        <p className="mb-4">Working to expose corruption through research, policy recommendations, and by holding institutions accountable.</p>
                        <Link className="btn btn-outline-primary btn-sm " to="/works">
                            Read More
                            <div className="d-inline-flex btn-sm-square bg-outline-primary text-white rounded-circle ms-2">
                                <i className="fa fa-arrow-right"></i>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                        <i className="fa fa-users  mb-3 fa-4x text-primary "/>
                        <h4 className="mb-3 text-primary">Human Rights Protection</h4>
                        <p className="mb-4">Ensuring the rights of citizens is upheld by advocating for fair treatment and justice.</p>
                        <Link className="btn btn-outline-primary btn-sm " to="/works">
                            Read More
                            <div className="d-inline-flex btn-sm-square bg-outline-primary text-white rounded-circle ms-2">
                                <i className="fa fa-arrow-right"></i>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                        <i className="fa fa-shield  mb-3 fa-4x text-primary "/>
                        <h4 className="mb-3 text-primary">Policy Advocacy</h4>
                        <p className="mb-4">Encouraging and influencing policy changes that serve the national interest and benefit the common good.</p>
                        <Link className="btn btn-outline-primary btn-sm " to="/works">
                            Read More
                            <div className="d-inline-flex btn-sm-square bg-outline-primary text-white rounded-circle ms-2">
                                <i className="fa fa-arrow-right"></i>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
      </div>

     <div className="row homesectionbg match-height">
      <div className="col-sm-12 col-md-6 mt-4">
        <div className="card-body px-3  mt-2 ">
           <iframe
            src="" 
             scrolling="no" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
           title='intro'></iframe>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 p-5 text-white">
        <h3 className='mt-2'> Outreach Programmaes</h3>
        <p className=' fs-6 mt-4 mb-3 fw-lighter'>
        Many educational materials in local languages are often out of date.
          We engange in numerous outhreach programs.
        </p>

        <button type="button" className="btn btn-light px-5 py-3 rounded-0"> <small> Read More </small> </button>
      </div>
      <div className="col-sm-12 col-md-6 p-5 text-white">
        <h3 className='mt-2'> Outreach Programmaes</h3>

        <p className='fw-lighter mt-4 mb-3'>
          <small>
        We create bespoke continuing education programs to address the needs of teachers and their student communities.
        From training on the latest technology to new pedagogical methods, 
        we help teachers make their classrooms engaging and successful.
        </small>
        </p>
        <button type="button" className="btn btn-light px-5 py-3 rounded-0"> <small> Read More </small> </button>
      </div>     
       <div className="col-sm-12 col-md-6 p-3">
       <div className="card-body ">
       <iframe title='Trip to Madina'  width={'100%'} height={'100%'} src=""  
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
       referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
           {/* <iframe className="embed-responsive-item"  width="560" height="315"  src="https://youtu.be/dQw4w9WgXcQ" title='intro' allowFullScreen></iframe> */}
        </div>
        {/* <img src="/assets/img/logosmall.png" className='' alt="" width="350" height="350" /> */}
       </div>
      <div className="col-sm-12 col-md-6 p-5">
        {/* <img src="/assets/img/logo.jpeg" className='img-fluid' alt="" width="350" height="150" /> */}
      </div>
      {/* <div className="col-sm-12 col-md-6 p-5 text-white">
        <h3 className='mt-2'> Support  for Communities</h3>
        <p className='fs-6 fw-lighter'>
        <small>
        Education happens within the context of a larger community. 
        Our approach extends beyond the school to help ensure students have the necessary support to do their best learning
        </small>
        </p>
 
        <button type="button" className="btn btn-light px-5 py-3 rounded-0"> <small> Read More </small> </button>
      </div> */}
     </div>
     <div className="row mx-5 my-5">
      <div className="col-sm-12 col-md-6 mt-5">
      <h2 className='fs-1'>
        "Strong government doesn't mean simply military power or an efficient intelligence apparatus. Instead, it should mean effective, fair administration - in other words, 'good governance.'."
      </h2>
     <p className='fs-6'>  <small className=' fw-lighter mt-4'> -- Raghuram Rajan </small>
     </p>
      </div>
      <div className="d-none d-md-block col-auto mx-auto  text-center ">
      <img src="/assets/img/logo.jpeg"  className='' alt="dropImage"  height="300" />
      </div>
     </div>
     <div className="row mx-4  px-5  py-5  my-4 bg-light">
      <div className="col-sm-8 col-md-7 ">
      <h6 className='fs-2 fw-light mb-3' > Join as a member or volunteer  
        <p className='fs-6 text-muted'>Participate in our events and initiatives</p>
      </h6>
      </div>
      <div className="col-auto text-end">
        <Link  className="btn btn-success  btn-lg rounded-0  px-4 py-2" to="/contacts"> Join Us </Link>
      </div>

     </div>
     {/* CONTSCT SECTION */}
     <div className="row mx-5 mt-5 pt-5">
    <div className="col-sm-12 col-md-6  ">
      <h3 className="fw-normal fs-1 "> Contact   </h3>
      <p className='fs-6 fw-light'>For inquiries or to learn more about our work, please reach out using the contact information below.</p>
      <h6 className='fw-bold mb-0 mt-5'>Email</h6>
      <p className='fw-light'> info@fdag.com </p>
      <h6 className='fw-bold mb-0 mt-5'>Phone</h6>
      <p className='fw-light'>(+233) 20 938-9765</p>
      <p className='fw-light'> +1(732) 496-5113</p>
      <p className='fw-light'> (+233) 24 995-2709</p>
      
    </div>
    <div className="col-sm-12 col-md-6 ">
    <Form/>
    </div>
     </div>
    </div>
    </>
  )
}

export default Home
