import React, { useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'

const Blog = () => {
    // Dont use long titles it mkae the title a lot
    const [blogs, setBlogs] = useState([]);
    const [spinner,setSpinner]= useState(true);
    useEffect(() => { 
        fetch('/assets/json/blog.json')
            .then(response => response.json())
            .then(data => {
                setBlogs(data);
                setSpinner(false);})
            .catch(error => console.error("Error loading data:", error));
    }, []);

  return (
    <>
     <div className="container-fluid press-header mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
            <h1 className="display-4 text-white fw-bold animated slideInDown mb-4">Press Release </h1>
             {/* <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item"><Link className="text-white" to="/">Home</Link></li>
                    <li className="breadcrumb-item text-primary active" aria-current="page">  Press Release </li>
                </ol>
            </nav>  */}
        </div>
    </div>
    
    {
    spinner ?(<> 
     <div className='py-5'>
     <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-75 top-50 py-5 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-grow text-primary " role="status"></div>
    </div>
     </div>
    </>) : 
    (<> 
    
    <div className='container mt-5'>
        <div className="row match-height">
            { blogs.map(data=>(
                <div key={data.id} className="col-sm-12 col-md-4 p-4 mx-auto shadow-sm">
                <img src="assets/img/pressrelease.jpeg"  className='mb-2 img-fluid' alt="Shots" height="410px" />
                <small className='mt-2 fs-6 text-small-1'>{data.releaseDate} </small>
                <h6 className="my-2 fw-bolder">
                     {data.blogTitle}
                </h6>
                <p className='fw-lighter fs-6 '> 
                    <small>
                            {data.hook}   
                    </small>
                 </p>
                 <Link to={'/releases/' + data.id}  state={{ data }} className='text-danger  fw-lighter fs-6 '> Read More </Link>
                </div>
            ))}
        </div>
        <Outlet />
    </div>
    
    </>)}

    


   
    </>
  )
}

export default Blog
