import {  NavLink} from  'react-router-dom'
import { useEffect, useState } from 'react';

function NavbarHome(){
    const [scrolled, setScrolled] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [open, IsOpened] = useState(false);
    const navLinkStyle = ({isActive})=>{
        return {
            fontWeight:isActive ? 'bold': 'normal',
            textDecoration:isActive ? 'none':'none'
        }
    }
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const windowWidth = window.innerWidth;

            if (windowWidth < 992) {
                setScrolled(scrollTop > 45);
            } else {
                setScrolled(scrollTop > 45);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <div className={`container-fluid py-0  px-0 wow fadeIn fixed-top  ${scrolled ? 'bg-dark' : ''} `}  style={{ top: scrolled && window.innerWidth >= 992 ? '-45px' : '0' }}  data-wow-delay="0.1s">
        <div className="top-bar text-white-50 row gx-0 align-items-center d-none d-lg-flex">
            <div className="col-lg-6 px-5 text-start">
                <small><i className="fa fa-map-marker-alt me-2"></i>GPS Address: GS-0277-0790, GH</small>
                <small className="ms-4"><i className="fa fa-envelope me-2"></i>info@fdag.com</small>
            </div>
            <div className="col-lg-6 px-5 text-end">
                <small>Follow us:</small>
                <a className="text-white-50 ms-3" target='_blank'  rel="noreferrer" href="https://www.facebook.com/profile.php?id=100077951392002"><i className="fab fa-facebook-f"></i></a>
                <a className="text-white-50 ms-3" target='_blank'  rel="noreferrer" href="/"><i className="fab fa-twitter"></i></a>
                <a className="text-white-50 ms-3" target='_blank'  rel="noreferrer" href="/"><i className="fab fa-tiktok"></i></a>
                <a className="text-white-50 ms-3" target='_blank'   rel="noreferrer" href="/"><i className="fab fa-instagram"></i></a>
            </div>
        </div>

         <nav className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
            <NavLink className="navbar-brand fw-bolder fs-4" to="/home">
                <h1 className="fw-bold text-primary m-0 d-flex align-items-center">FD<span className="text-white">AG</span>  <img className='mt-0 ms-3 ' src='/assets/img/logosmall.png' width='35em'  alt='logo' / >  </h1>
            </NavLink>
            <button type="button" className="navbar-toggler border-0  me-4"  data-bs-toggle="collapse" data-bs-target="#navbarCollapse" onClick={() => {setExpanded(!expanded) ;IsOpened(!open)}}>
            {  open ? <i className='fa fa-close fa-lg '></i> :  <span className="navbar-toggler-icon"></span>}
            </button>
            <div className= {`collapse navbar-collapse ${open ? 'show' : ''}`}  id="navbarCollapse">
                <div className="navbar-nav ms-auto p-4 p-lg-0">
                    <NavLink className='nav-link' onClick={() => IsOpened(!open)}  style={navLinkStyle} to='/home' > Home</NavLink>
                    <NavLink className='nav-link'  onClick={() => IsOpened(!open)} style={navLinkStyle} to='/about' > About</NavLink>
                    <NavLink className='nav-link' onClick={() => IsOpened(!open)} style={navLinkStyle} to='/works' > Our Causes</NavLink>
                    <NavLink className='nav-link' onClick={() => IsOpened(!open)} style={navLinkStyle} to='/media' > Media</NavLink>
                    <NavLink className='nav-link' onClick={() => IsOpened(!open)} style={navLinkStyle} to='/releases' > Press Release</NavLink>
                    <NavLink className='nav-link'  onClick={() => IsOpened(!open)} style={navLinkStyle} to='/contacts' > Contact</NavLink>
                </div>
                <div className="d-none d-lg-flex ms-2">
                    <a className="btn btn-sm btn-white text-white border-white py-2 px-3" href="/">
                        Donate Now
                        <div className="d-inline-flex btn-sm-square bg-white border-white text-primary rounded-circle ms-2">
                            {/* <i className="fa fa-arrow-right"></i> */}
                        </div>
                    </a>
                </div>
            </div>
        </nav>
    </div>
  )
}

export default NavbarHome;
